import React from 'react';
import styled from 'styled-components';

const CancelBtnContainer = styled.a`
  font-size: 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline;
`;

const CancelButton = ({ noButtonTitle, onCancelClick, disabled, ...props }) => {
  return (
    <CancelBtnContainer
      onClick={() => {
        if (!disabled) {
          onCancelClick?.();
        }
      }}
      {...props}
    >
      {noButtonTitle}
    </CancelBtnContainer>
  );
};

export default CancelButton;
