import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { tablet } from 'styles/breakpoints';

interface LabelProps {
  text: string;
  padding?: string;
  bgColor?: string;
  align?: string;
}

const Container = styled.div<{ padding: string; bgColor?: string }>`
  padding: ${({ padding }) => padding};
  background-color: ${({ theme, bgColor }) => bgColor};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 35rem;
  @media ${tablet} {
    max-width: 21.4375rem;
  }
`;

const TextStyled = styled(Text)<{ align: string }>`
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  text-align: ${({ align }) => align};
`;

const Label: FC<LabelProps> = ({
  text,
  padding = '0',
  bgColor,
  align = 'center',
  ...props
}) => (
  <Container padding={padding} bgColor={bgColor} {...props}>
    <InnerContainer>
      <TextStyled dangerouslySetInnerHTML={{ __html: text }} align={align} />
    </InnerContainer>
  </Container>
);

export default Label;
